import StringConversionService from "~/services/StringConversionService";
type options = {
  button: boolean;
  label: string;
  noIcon: boolean;
  type: string;
};
export const useSalesContactButton = (options: options) => {
  function generateUrl(type: string = "general") {
    return `@show${type === "general" ? "Schedule" : _upperFirst(StringConversionService.toCamelCase(type))}Popup`;
  }

  const linkData = computed(() => ({
    link: { url: generateUrl(options.type), linktype: "url" },
    linkText: options?.label,
    ...options,
    component: "link-link",
  }));

  return {
    linkData,
  };
};
